<template>
    <nav class="categories-nav_wrap-wrap">
        <div class="categories-nav_wrap" :class="{ 'categories-nav_wrap-mobile': showCategories }">
            <h5 v-if="currentCategory && showingSubs" class="categories_nav-heading">
                <a :href="currentCategory.url">{{ currentCategory.name }}</a>
            </h5>
            <ul class="categories-nav">
                <li v-for="category in list" class="categories_nav-item">
                    <a :href="category.url">{{ category.name }}</a>
                </li>
            </ul>
        </div>

        <a v-if="showingSubs" href=""
           @click.prevent="showingSubs = false; currentCategory = null"
           class="categories_nav-header-all">All Categories</a>

        <a href="" @click.prevent="categoryToggle"
           class="categories_nav-view_link"
           :class="{ 'categories_nav-view_link-open' : showCategories }"
           v-html="showCategories ? 'Hide Categories' : 'View Categories'"></a>
    </nav>
</template>

<script>
import find from 'lodash/find';

export default {
    props: {
        categories: {
            type: Array,
            required: true,
        },
        subs: {
            type: Array,
        },
        currentUrl: {
            type: String,
        },
    },

    data () {
        return {
            showingSubs: this.subs.length > 1,
            currentCategory: find(this.categories, { url: this.currentUrl }),
            showCategories: false,
        };
    },

    computed: {
        list () {
            if (this.showingSubs) {
                return this.subs;
            }

            return this.categories;
        },
    },

    methods: {
        categoryToggle() {
            this.showCategories = !this.showCategories;
        },
    },
}
</script>
