import Vue from 'vue';
import Vuex from 'vuex';

import cart from './cart/store';

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,
    namespaced: true,
    state: {
        serverData: {},
        moneyDivisor: 100000,
    },
    getters: {},
    actions: {},
    mutations: {
        updateServerData (state, serverData) {
            state.serverData = serverData;
        },
    },

    modules: {
        cart,
    }
});
