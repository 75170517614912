<template>
    <div style="height: 0; width: 0; position: absolute; visibility: hidden;" v-html="svg"></div>
</template>

<script>
// @todo copy to YBD
// @todo copy to Eximo
// @todo copy to READI
import axios from './axios';
import { logError } from './lib';

export default {
    props : ['src'],
    data() {
        return {
            svg : ''
        };
    },

    mounted() {
        axios.get(this.src)
            .then((response) => {
                this.svg = response.data;
            })
            .catch((e) => {
                logError(e);
            });
    }
}
</script>