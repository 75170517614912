import 'es6-promise/auto';

import Vue from 'vue';
import VueRouter from 'vue-router';
import VueSilentbox from 'vue-silentbox';
import VueScrollTo from 'vue-scrollto';
import PortalVue from 'portal-vue';
import store from './public/store';

import svgIcons from './common/svg_icons';
import fieldErrors from './common/field_errors';
import categoryNav from './public/category_nav';
import { nl2brFilter, percentFilter, moneyFilter, priceFilter, phoneFilter } from './common/lib';

// SASS/CSS
import '../../css/sass/public.scss';

// images
import '../../images/icons-public.svg';

// disable the warning about dev/prod
Vue.config.productionTip = false;

Vue.use(VueRouter);
// http://silentbox.silencesys.com/
Vue.use(VueSilentbox);
Vue.use(VueScrollTo);
Vue.use(PortalVue);

Vue.component('field-errors', fieldErrors);
Vue.component('cart-add', () => import('./public/cart/add'));
Vue.component('modal', () => import('./common/modal'));

Vue.filter('money', moneyFilter);
Vue.filter('price', priceFilter);
Vue.filter('percent', percentFilter);
Vue.filter('phone', phoneFilter);
Vue.filter('nl2br', nl2brFilter);

window.App = new Vue({
    el: '#app',
    store,
    components: {
        'svg-icons': svgIcons,
        'cart': () => import('./public/cart/index'),
        'cart-summary': () => import('./public/cart/summary'),
        'cart-pullout': () => import('./public/cart/pullout'),
        'cart-checkout': () => import('./public/cart/checkout'),
        'cart-view': () => import('./public/cart/view'),
        'category-nav': categoryNav,
        'variant-selection': () => import('./public/variant_selection'),
        'variant-details': () => import('./public/variant_details'),
        'profile-form': () => import('./public/profile_form'),
    },

    mounted() {
        if (this.$el.dataset && this.$el.dataset.serverData) {
            this.$store.commit('updateServerData', JSON.parse(this.$el.dataset.serverData));
        }
    },
});
