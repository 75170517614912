import Cookie from 'js-cookie';
import debounce from 'lodash/debounce';
import find from 'lodash/find';
import axios from '@/common/axios';
import { logError, findProductKey } from '@/common/lib';
import checkout from './checkout/store';

const state = {
    /**
     * True means the cart is currently being loaded.
     */
    updating: false,
    /**
     * True means the local & server cart are in sync
     */
    synced: false,
    saving: false,
    summaryLoaded: false,
    currentView: null,
    urls: {},
    csrfToken: null,
    cartUuid: null,
    hasSeparateOrderProduct: false,
    pulloutOpen: false,
    productCount: 0,
    products: [],
    totals: {
        subTotal: 0, // cents
        discount: null, // cents
        otherProductTotal: 0, // cents
        shipping: 0, // cents
        shippingFree: false,
        shippingActualCost: false,
        taxRate: 0, // decimal percentage
        tax: 0, // cents
        grandTotal: 0, // cents
    },
};

const getters = {
    hasCart (state) {
        return !!state.cartUuid;
    },

    isEmpty (state) {
        return state.productCount === 0;
    },

    productArrayForServer (state) {
        return state.products.map((product) => {
            return {
                productId: product.productId,
                productVariantId: product.productVariantId,
                quantity: product.quantity,
            };
        });
    },
};

const actions = {
    addProduct ({ commit, state, dispatch }, { productId, productVariantId, quantity }) {
        return new Promise(async (resolve, reject) => {
            commit('startUpdating');
            commit('outOfSync');

            try {
                const data = {
                    productId,
                    productVariantId,
                    quantity,
                    _csrf_token: state.csrfToken,
                };

                const response = await axios.post(state.urls.addProduct, data);

                // if successful, update the summary data from the response
                if (response.data.success) {
                    dispatch('findCartUuidInCookie');
                    dispatch('updateSummaryData', response.data);

                    if (state.pulloutOpen) {
                        await dispatch('loadCart');
                    }
                }

                commit('stopUpdating');

                if (response.data.success) {
                    resolve();

                } else {
                    reject();
                }

            } catch (e) {
                if (e.response.status === 403) {
                    alert('We are unable to add the product to the cart because your account is not configured correctly. Please ensure you have a shipping and billing address and phone number.');

                    commit('stopUpdating');

                    resolve();

                    return;
                }

                if (e.response.status === 410) {
                    alert('The selected item is no longer available.');

                    commit('stopUpdating');

                    // as the product's no longer available,
                    // ensure the page is refreshed so they get the latest
                    window.location.reload();

                    resolve();

                    return;
                }

                logError(e);
                commit('stopUpdating');

                alert('There was a problem adding the product to your cart. The product may no longer be available. Try refreshing the page and trying again.');

                reject();
            }
        });
    },

    saveQuantities ({ state, getters, commit, dispatch }) {
        return new Promise(async (resolve, reject) => {
            try {
                commit('saving');

                const data = {
                    products: getters.productArrayForServer,
                    view: state.currentView,
                    _csrf_token: state.csrfToken,
                };

                const response = await axios.post(state.urls.updateQuantities, data);

                if (response.data.success) {
                    dispatch('updateCartData', response.data);
                    commit('inSync');
                    commit('saved');

                    resolve();
                }

            } catch (e) {
                logError(e);

                // there was a problem, so just reload the pullout so they can try again
                if (state.pulloutOpen) {
                    await dispatch('loadCart');
                }
            }

            reject();
        });
    },

    async removeProduct ({ state, commit, dispatch }, product) {
        try {
            commit('outOfSync');

            const data = {
                product: {
                    productId: product.productId,
                    productVariantId: product.productVariantId,
                },
                view: state.currentView,
                _csrf_token: state.csrfToken
            };

            const response = await axios.post(state.urls.removeProduct, data);

            if (response.data.success) {
                commit('removeProduct', product);
                dispatch('updateCartData', response.data);
                commit('inSync');
            }

        } catch (e) {
            logError(e);

            // there was a problem, so just reload the pullout so they can try again
            if (state.pulloutOpen) {
                await dispatch('loadCart');
            }
        }
    },

    slowSaveQuantities: debounce(async ({ dispatch }) => {
        await dispatch('saveQuantities');
    }, 500),

    updateQuantity ({ commit, dispatch }, { product, newQuantity }) {
        commit('updateQuantity', { product, newQuantity });
        commit('outOfSync');
        dispatch('slowSaveQuantities');
    },

    updateCart ({ commit, dispatch }) {
        return new Promise(async (resolve, reject) => {
            commit('outOfSync');
            await dispatch('saveQuantities');

            resolve();
            // @todo - what else? anything?
        });
    },

    togglePullout ({ commit, state, dispatch }) {
        if (!state.pulloutOpen) {
            commit('openPullout');
        } else {
            commit('closePullout');
        }

        if (state.pulloutOpen && state.cartUuid) {
            dispatch('loadCart');
        }
    },

    openPullout ({ commit, state, dispatch }) {
        if (!state.pulloutOpen && state.cartUuid) {
            commit('openPullout');
            dispatch('loadCart');
        }
    },

    async loadSummary ({ getters, dispatch }) {
        if (!getters.hasCart) {
            return;
        }

        try {
            const response = await axios.get(state.urls.load.summary, { params: { _csrf_token: state.csrfToken } });

            if (response.status === 200) {
                dispatch('updateSummaryData', response.data);
            }

        } catch (e) {
            logError(e);
        }
    },

    // @todo make as a promise or remove usage as a promise
    async loadCart ({ commit, state, dispatch }, { view = null } = {}) {
        commit('startUpdating');
        commit('outOfSync');

        try {
            const response = await axios.get(
                state.urls.load[view || state.currentView],
                { params: { _csrf_token: state.csrfToken } }
            );

            if (response.status === 200) {
                dispatch('updateCartData', response.data);
            }

            commit('stopUpdating');
            commit('inSync');

        } catch (e) {
            logError(e);
            if (e.response && e.response.data.noCart) {
                dispatch('clearCart');
                commit('stopUpdating');
                commit('inSync');
            } else {
                alert('There was a problem loading your cart. Please try again later.');
            }
        }
    },

    async emptyCart ({ commit, dispatch, state }) {
        if (state.productCount > 3 && !confirm('Are you sure you want to remove all the products from your cart?')) {
            return;
        }

        commit('startUpdating');

        try {
            const response = await axios.get(state.urls.emptyCart, { params: { _csrf_token: state.csrfToken } });

            if (response.status === 200) {
                dispatch('clearCart');
            }

            commit('stopUpdating');

        } catch (e) {
            logError(e);

            alert('There was a problem emptying your cart. Please try again later.');
        }
    },

    // @todo reset totals? anything else?
    clearCart ({ commit }) {
        commit('updateProductCount', 0);
        commit('updateProducts', []);
        commit('setCartUuid', null);
    },

    updateSummaryData ({ commit }, data) {
        commit('updateProductCount', data.productCount);
        commit('updateGrandTotal', data.totals.grandTotal);
        commit('setHasSeparateOrderProduct', data.hasSeparateOrderProduct);
        commit('summaryLoaded');
    },

    updateCartData ({ commit }, data) {
        commit('updateProductCount', data.productCount);
        commit('updateProducts', data.products);
        commit('updateTotals', data.totals);
        commit('setHasSeparateOrderProduct', data.hasSeparateOrderProduct);
    },

    findCartUuidInCookie ({ commit }) {
        // @todo do we still want to load even if we already have?
        const cartUuid = Cookie.get('cart');

        if (cartUuid) {
            commit('setCartUuid', cartUuid);
        }
    },
};

const mutations = {
    startUpdating (state) {
        state.updating = true;
    },
    stopUpdating (state) {
        state.updating = false;
    },
    inSync (state) {
        state.synced = true;
    },
    outOfSync (state) {
        state.synced = false;
    },
    saving (state) {
        state.saving = true;
    },
    saved (state) {
        state.saving = false;
    },
    summaryLoaded (state) {
        state.summaryLoaded = true;
    },
    // summaryNotLoaded (state) {
    //     state.synced = false;
    // },

    updateUrls (state, urls) {
        state.urls = urls;
    },
    setViewUrl (state, url) {
        state.urls.view = url;
    },
    updateCsrfToken (state, csrfToken) {
        state.csrfToken = csrfToken;
    },
    setCurrentView (state, view) {
        state.currentView = view;
    },

    setCartUuid (state, cartUuid) {
        state.cartUuid = cartUuid;
    },

    openPullout (state) {
        state.pulloutOpen = true;
        state.currentView = 'pullout';
    },
    closePullout (state) {
        state.pulloutOpen = false;
        state.currentView = null;
    },

    updateProductCount (state, count) {
        state.productCount = count;
    },
    updateProducts (state, products) {
        // products maybe an object because of PHP's json serialization
        // of an array without ordered keys
        // this will basically create an array out of the product values
        if (typeof products === 'object') {
            products = Object.keys(products).map(key => products[key]);
        }

        state.products = products;
    },
    setHasSeparateOrderProduct (state, status) {
        state.hasSeparateOrderProduct = status;
    },
    /**
     * Stores the totals and also calculates the other products total and tax.
     */
    updateTotals (state, totals) {
        state.totals = totals;

        const displayedProductsTotal = state.products.map((product) => {
            // to get cents
            return product.price * product.quantity / 1000;
        }).reduce((a, b) => a + b, 0).toFixed(2);

        state.totals.otherProductTotal = state.totals.subTotal - displayedProductsTotal;
        state.totals.tax = (state.totals.subTotal - state.totals.discount + state.totals.shipping) * state.totals.taxRate;
    },
    updateGrandTotal (state, grandTotal) {
        state.totals.grandTotal = grandTotal;
    },
    updateQuantity (state, { product, newQuantity }) {
        const _product = find(state.products, (_product) => {
            return _product.productId === product.productId && _product.productVariantId === product.productVariantId;
        });

        _product.quantity = parseInt(newQuantity, 10);
    },
    removeProduct (state, product) {
        state.products.splice(findProductKey(state.products, product), 1);
        state.productCount--;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,

    modules: {
        checkout,
    },
}
