import { parse as parsePhone, format as formatPhone } from 'libphonenumber-js';
import escapeHtml from 'escape-html';
import findIndex from 'lodash/findIndex';

export const logError = function (e) {
    if (console && e !== undefined) {
        console.error(e);
    }
};

export const nl2brFilter = function (str) {
    return escapeHtml(str).replace(/\n/g, '<br>');
};

export const percentFilter = function (value) {
    return parseFloat(value * 100)+'%';
};

export const moneyFilter = function (value) {
    const price = parseFloat(value / 100);

    // from what I'm reading, Android doesn't fully support the locales and options
    try {
        return price.toLocaleString('en-CA', {
            style: 'currency',
            currency: 'CAD'
        });
    } catch (e) {
        return '$'+price.toFixed(2);
    }
};

export const priceFilter = function (value) {
    const price = parseFloat(value / 100000).toFixed(5);

    const decimals = Math.max(2, decimalPlaces(price));

    return '$'+(+price).toFixed(decimals);
};

function decimalPlaces(number) {
    // The ^-?\d*\. strips off any sign, integer portion, and decimal point
    // leaving only the decimal fraction.
    // The 0+$ strips off any trailing zeroes.
    return +number.replace(/^-?\d*\.?|0+$/g, '').length;
}

export const phoneFilter = function (phone, format = 'International') {
    if (!phone || phone === '+') {
        return;
    }

    const parsed = parsePhone(phone, 'CA');

    return formatPhone(parsed, format);
};

export const orderStatusName = function (status) {
    switch (status) {
        case 'new':
            return 'New';
        case 'submitted':
            return 'Submitted';
        case 'paid':
            return 'Paid';
        case 'emptied':
            return 'Emptied';
        case 'cancelled':
            return 'Cancelled';
    }
};

export const findProductKey = function (products, product) {
    return findIndex(products, (_p) => {
        return _p.productId === product.productId && _p.productVariantId === product.productVariantId;
    });
};
