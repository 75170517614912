import clone from 'lodash/clone';
import defaults from 'lodash/defaults';
import filter from 'lodash/filter';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import axios from '@/common/axios';
import { logError } from '@/common/lib';

const state = {
    // 0 indexed
    currentStep: 0,
    // the names of the router steps, in order
    // the index is used
    steps: [
        'view', 'shipping', 'billing', 'details', 'payment', 'review',
    ],

    validationErrors: {},

    allowedPaymentMethods: [],
    states: [],
    countries: [
        { name: 'Canada', code: 'CA' },
        { name: 'United States', code: 'US' },
    ],

    email: null,
    phone: null,
    deliveryMethod: null,
    shippingAddress: {
        name: null,
        company: null,
        line1: null,
        line2: null,
        city: null,
        state: null,
        postalCode: null,
        country: null,
    },
    billingAddress: {
        sameAsShipping: true,
        name: null,
        company: null,
        line1: null,
        line2: null,
        city: null,
        state: null,
        postalCode: null,
        country: null,
    },
    // entered by user
    cardholderName: null,
    stripeToken: {},
    // from Stripe
    userCardDetails: {
        brand: null,
        last4: null,
        expMonth: null,
        expYear: null,
    },

    poNumber: null,
    notes: null,
    orderNum: null,
    // their selected payment method
    paymentMethod: null,
};

const getters = {
    indexOfStep: (state) => (step) => {
        return state.steps.indexOf(step);
    },
    stepDiff: (state, getters) => (to, from) => {
        return getters.indexOfStep(to) - getters.indexOfStep(from);
    },

    shippingStates (state) {
        const country = state.shippingAddress.country || 'CA';

        return filter(state.states, (s) => {
            return s.country === country;
        });
    },
    billingStates (state) {
        const country = state.billingAddress.country || 'CA';

        return filter(state.states, (s) => {
            return s.country === country;
        });
    },

    addressDisplayData: (state) => (address) => {
        const key = address+'Address';

        return {
            name: state[key].name,
            company: state[key].company,
            line1: state[key].line1,
            line2: state[key].line2,
            city: state[key].city,
            state: state[key].state,
            postalCode: state[key].postalCode,
            country: state[key].country,
        };
    },

    showValidationErrors (state) {
        return !isEmpty(state.validationErrors);
    },
    getValidationErrorsFor: (state) => (path) => {
        return get(state.validationErrors, path, []);
    },

    showPaymentStep (state) {
        return state.paymentMethod === 'credit_card';
    },
};

const actions = {
    setStep ({ commit, getters }, step) {
        commit('setStep', getters.indexOfStep(step));
    },

    async loadCheckout ({ dispatch, commit, rootState }) {
        // @todo why this and then load again below?
        await dispatch('cart/loadCart', {}, { root: true });

        commit('cart/startUpdating', null, { root: true });
        commit('cart/outOfSync', null, { root: true });

        try {
            const response = await axios.get(
                rootState.cart.urls.checkout.load,
                { params: { _csrf_token: rootState.cart.csrfToken } }
            );

            if (response.status === 200) {
                dispatch('updateCartData', response.data.cart);
                commit('setAllowedPaymentMethods', response.data.allowedPaymentMethods);
                commit('setStates', response.data.states);
            }

            commit('cart/stopUpdating', null, { root: true });
            commit('cart/inSync', null, { root: true });

        } catch (e) {
            logError(e);

            alert('There was a problem loading your cart. Please try again later.');
        }
    },

    // can save the shipping, billing and details steps
    saveStep ({ commit, rootState }, { step, data }) {
        return new Promise(async (resolve, reject) => {
            commit('cart/outOfSync', null, { root: true });

            try {
                data._csrf_token = rootState.cart.csrfToken;

                await axios.post(rootState.cart.urls.checkout[step], data);

                commit('clearValidationErrors');

                resolve();

            } catch (e) {
                if (e.response && e.response.status === 400) {
                    commit('setValidationErrors', e.response.data.validationErrors);

                } else {
                    logError(e);

                    alert('There was a problem saving your cart. Please try again later.');
                }

                reject(e);
            }

            commit('cart/inSync', null, { root: true });
        });
    },

    placeOrder ({ commit, state, rootState }) {
        return new Promise(async (resolve, reject) => {
            commit('cart/outOfSync', null, { root: true });

            try {
                const data = {
                    _csrf_token: rootState.cart.csrfToken,
                };
                if (state.stripeToken && state.stripeToken.id) {
                    data.cardToken = state.stripeToken.id;
                }

                const response = await axios.post(rootState.cart.urls.checkout.placeOrder, data);

                commit('cart/setViewUrl', response.data.viewUrl, { root: true });
                // [!!] don't set to insync as it will allow users
                // [!!] to switch tabs before the redirect takes place

                resolve();

            } catch (e) {
                commit('cart/inSync', null, { root: true });

                if (e.response && e.response.status === 400) {
                    commit('setValidationErrors', e.response.data.validationErrors);

                } else {
                    logError(e);

                    alert('There was a problem completing your order. Please contact PacCana before trying again.');
                }

                reject(e);
            }
        });
    },

    updateCartData ({ commit }, data) {
        commit('setEmail', data.email);
        commit('setPhone', data.phone);

        commit('setShippingAddress', transformServerAddress(data.shippingAddress));
        if (data.billingAddressSameAsShippingAddress) {
            commit('setBillingSameAsShipping');
        } else {
            commit('setBillingAddress', transformServerAddress(data.billingAddress));
        }

        commit('setNotes', data.notes);
        commit('setPoNumber', data.poNumber);

        commit('setCardholderName', data.cardholderName);
        commit('setPaymentMethod', data.paymentMethod);
        commit('setDeliveryMethod', data.deliveryMethod);
    },
};

const mutations = {
    setStep (state, step) {
        state.currentStep = step;
        state.validationErrors = {};
    },

    setValidationErrors (state, errors) {
        state.validationErrors = errors;
    },
    clearValidationErrors (state) {
        state.validationErrors = {};
    },

    setAllowedPaymentMethods (state, methods) {
        state.allowedPaymentMethods = methods;
    },
    setStates (state, states) {
        state.states = states;
    },

    setEmail (state, email) {
        state.email = email;
    },
    setPhone (state, phone) {
        state.phone = phone;
    },
    setDeliveryMethod (state, deliveryMethod) {
        state.deliveryMethod = deliveryMethod;
    },
    setShippingAddress (state, address) {
        state.shippingAddress = clone(defaults(address, defaultAddress()));
    },
    setBillingSameAsShipping (state) {
        state.billingAddress = defaultAddress();
        state.billingAddress.sameAsShipping = true;
    },
    setBillingAddress (state, address) {
        state.billingAddress = clone(defaults(address, defaultAddress()));
        state.billingAddress.sameAsShipping = false;
    },
    setPoNumber (state, poNumber) {
        state.poNumber = poNumber;
    },
    setNotes (state, notes) {
        state.notes = notes;
    },
    setPaymentMethod (state, paymentMethod) {
        state.paymentMethod = paymentMethod;
    },

    setStripeToken (state, token) {
        state.stripeToken = token;
    },
    setUserCardDetails (state, details) {
        state.userCardDetails = details;
    },
    setCardholderName (state, name) {
        state.cardholderName = name;
    },
};

// @todo do we still need this?
const transformServerAddress = function (address) {
    address = defaults(address, defaultAddress());

    return {
        name: address.name,
        company: address.company,
        line1: address.line1,
        line2: address.line2,
        city: address.city,
        state: address.state,
        postalCode: address.postalCode,
        country: address.country,
    }
};

const defaultAddress = function () {
    return {
        name: null,
        company: null,
        line1: null,
        line2: null,
        city: null,
        state: null,
        postalCode: null,
        country: null,
    };
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}